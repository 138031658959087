html {
  font-size: 0.75rem;
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }
}

body {
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: "Noto Sans", sans-serif;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

:root {
  --csa-bg-secondary: #f8f8f8;
  --csa-bg-tertiary: #ebebec;
  --csa-blue-2: rgba(0, 160, 255, 0.2);
  --csa-blue: #00a0ff;
  --csa-border: #999;
  --csa-border-2: #c4c4c4;
  --csa-border-3: #eceef6;
  --csa-darkgrey: #8d8e92;
  --csa-green-2: #d5f5e3;
  --csa-green: #2ecc71;
  --csa-grey: #c2c2c4;
  --csa-orange-2: rgba(255, 184, 0, 0.2);
  --csa-orange: #ffb800;
  --csa-red-2: #fdedeb;
  --csa-red: #e74c3c;
  --csa-text-primary: #363840;
}

.select-group {
  margin: 0.5rem;
  border-bottom: 1px solid var(--csa-border-2);
}

.select-group:last-child {
  border-bottom: none;
}
